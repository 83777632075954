import React, { useState } from 'react';

function Service() {
  const [showFullContent, setShowFullContent] = useState(false);

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  return (
    <div className='container bg-slate-50 grid grid-cols-12'>
        <div className='md:col-span-9 col-span-11'>
            <h1 className='font-bold text-center p-4 underline underline-offset-8'>
                OUR <span className='text-orange-500'>SERVICES</span>
            </h1>
            <div className="px-4 pb-4">
                <p className={showFullContent ? '' : 'line-clamp-5'}>
                At Hotspot Collectors Limited, we specialize in the outsourcing of the debt collection function of medium to large sized companies, individuals, government and non-governmental organizations. This involves the complete and comprehensive chasing of debtors and collection of dues from the start to end of the process. We normally work both from our main offices but particularly from the clients’ offices. We tend to have most of our staff working directly with the clients and we ensure that through adequate monitoring of our staff and coordination of efforts between our onsite and back-office staff we aim to achieve the best possible results which no business, we feel, would achieve by employing their own in-house members of staff. This is because we give a comprehensive package which includes trained employees chasing debtors who are also supported by our lawyers, consultants, finance specialists and our affiliation with Kenya’s leading credit monitoring institutions. Our agreed consultancy work, legal work and affiliations are not extended as a cost to our clients but are presented to our clients as part of our service offer at no additional cost.
                </p>
                {!showFullContent && (
                <button onClick={toggleContent} className="text-orange-500 hover:text-orange-700 focus:outline-none">
                    Read More
                </button>
                )}
            </div>
            <div className="px-4 pb-4">       
                <h1 className='pb-2 font-semibold'>DEBT COLLECTION</h1>
                <div>
                    <p>
                    Outstanding debts are the bane of many businesses, small, medium
                    and large. Late payments and the debt collection process can be
                    quite stressful for any business, whether a sole trader, SME or a
                    larger outfit and that is why we chip in and face it on your behalf.
                    We take over and handle the full process of commercial debt
                    collection & recovery reducing the in-house fixed costs to variable
                    cost-effective costs and deliver a service which is second to none.
                    </p>
                </div>
            </div>
            <div className="px-4 pb-4">       
                <h1 className='pb-2 font-semibold'>REVENUE MANAGEMENT AND AUDITS</h1>
                <div>
                    <p>
                    Our revenue management outsourcing consultants have an
                    extensive experience in banking, governmental and trade sector
                    industry, and leave no stone unturned to uncover the hidden
                    revenue potential of your business, company and institution to
                    increase your bottom line profit. We have a strong business
                    development DNA, and aim to challenge the status quo and
                    established order.
                    HotSpot Debt Managers’ RM and yield experts will be incorporated
                    in your institution management structure and operate as a part of
                    your team taking on the responsibilities and duties of revenue
                    management and business development. Working besides your
                    executive management, we will be implementing best practices,
                    proven techniques and the latest distribution developments. We will
                    help you outperform your competition and become a leader in your
                    local market.
                    </p>
                </div>
            </div>
            <div className="px-4 pb-4">       
                <h1 className='pb-2 font-semibold'>SKIP TRACING & INVESTIGATION</h1>
                <div>
                    <p>
                    We help you trace or locate debtors who have defaulted and your
                    customers or clients who have failed to pay you for services
                    rendered.
                    While working on a skip trace case to find someone, we use
                    everything from specialized databases, public records/information,
                    and open-source intelligence techniques to court filings and
                    property deeds. Given the sheer amount of digital and paper records
                    created by the average person, almost anyone can be located by an
                    investigator given sufficient time.
                    In a skip tracing case the person being located (know. as the person
                    of interest, or POI) doesn't wish to be found. In this case, we attempt
                    to locate the person's contact details, current address, and even
                    location in real-time.
                    The primary goal of our skip trace investigation is to determine the
                    current location of the debtor. Once the debtor is located, we may
                    act on this information directly by serving papers and making
                    contact with the individual
                    </p>
                </div>
            </div>
        </div>
        <div className='md:col-span-3'>
            <img className="h-full w-full object-cover" src="https://images.pexels.com/photos/3366171/pexels-photo-3366171.jpeg?cs=srgb&dl=pexels-jovydas-dobilas-3366171.jpg&fm=jpg" alt="glass building" />
        </div>
    </div>
  );
}

export default Service;
