import React, { useState } from 'react';
import { IoMdMenu } from "react-icons/io"; 
import { IoHomeSharp } from "react-icons/io5";
import { FaRegQuestionCircle } from "react-icons/fa";
import { IoIosBriefcase } from "react-icons/io"; 
import { FaSquarePhone } from "react-icons/fa6"; 
import logo from '../asset/lg.jpeg';  

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className='bg-slate-50'>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-around h-16">
            <div className="flex flex-row flex-shrink-0">
              <img className='nav-logo hidden md:block px-3' src={logo} alt="logo" />
              <h1 className='text-xl font-bold md:pt-5'>
                  <a className='text-orange-600' href="/">Hotspot <span className='text-pink-800'>Collectors</span> Limited</a>
              </h1>
            </div>
            <div className="flex items-center">
                <div className="block sm:hidden">
                    <button onClick={toggleMenu} className=" hover:text-gray-300 focus:outline-none focus:text-gray-300">
                        <IoMdMenu className='size-5'/>
                    </button>
                </div>
                <div className="hidden sm:block">
                    <div className="flex items-center nav">
                        <a href="/"
                        className=" hover:text-orange-600 px-3 py-2 rounded-md text-sm"
                        >
                        Home
                        </a>
                        <a href="/about"
                        className=" hover:text-orange-600 px-3 py-2 rounded-md text-sm"
                        >
                        About
                        </a>
                        <a href="/services"
                        className=" hover:text-orange-600 px-3 py-2 rounded-md text-sm"
                        >
                        Our Services
                        </a>
                        <a href="/contact"
                        className=" hover:text-orange-600 px-3 py-2 rounded-md text-sm"
                        >
                        Contact Us
                        </a>
                    </div>
                </div>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isOpen && (
        <div className="sm:hidden px-16 py-1">
            <div className="px-2 pt-2 pb-3 space-y-1">
                <a href="/" className=' flex'>                
                    <IoHomeSharp className='mr-2 mt-1'/>
                    <span>Home</span> 
                </a>
                <a href="/about" className=' flex'>               
                    <FaRegQuestionCircle className='mr-2 mt-1'/>
                    <span>About</span>
                </a>
                <a href="/services" className=' flex'>
                    <IoIosBriefcase className='mr-2 mt-1'/>
                    <span>Our Services</span>              
                </a>
                <a href="/contact" className=' flex'>
                    <FaSquarePhone className='mr-2 mt-1'/>
                    <span>Contact Us</span>              
                </a>
            </div>
        </div>
      )}
    </nav>
  );
};

export default Navigation;
