import React from 'react';
import warao from '../asset/warao.jpeg'

function About() {
  return (
    <div className='bg-fuchsia-900 text-white font-sans'>
        <div className='p-3'>
            <h1 className='underline underline-offset-8 uppercase font-bold text-2xl'>Company Overview</h1>
            <h2 className='pt-3 pb-2 font-semibold text-xl'>Our History</h2>
            <p className='border-b border-gray-300 pb-2'>Hotspot Collectors Limited was established in the year
            2014 to provide debt collection and recovery services for
            individuals, businesses, companies, government and nongovernmental organizations in East Africa.
            With the support of its dedicated staff with many years of hands
            on debt collection experience, the company has expanded at a
            commendable pace</p>
        </div>
        <div>
            <h1 className='p-3 text-lg underline underline-offset-8'>MEET <span className='text-orange-600'>OUR CEO</span></h1>
            <h2 className='px-3'>James Muriithi Ndwiga</h2>
            <h3 className='px-3 text-sm'>CEO of HotSpot Collectors Ltd</h3>
            <div className='md:grid grid-cols-2'>
                <div className='warao'>
                    <img className='md:p-8 p-3' src={warao} alt="ceo" />
                </div>
                <div className='p-3 ceo'>
                    James Muriithi personally handles all large debt collection matters and has negotiated more than $500 million in business transactions.
                    He is a positive, motivated and hard-working person. He has exceptional knowledge and skills in the banking and trading sector. He has worked at Standard Chartered Bank. 
                </div>
                
            </div>
            <p className='p-8'>
            While at Standard Chartered Bank, he was trusted to perform duties like:
                <ul className='list-disc'>
                    <li>Developing strategies of converting hardcore credit portfolio to paying portfolio.</li>
                    <li>Developing strategies of converting Skip Trace credit portfolio into paying
                    portfolio.</li>
                    <li>Replying to customer queries relating to Credit policies in the country and the Bank</li>
                    <li>Field Visiting clients and establishing nature of business in aid of collection.</li>
                    <li>Advising the bank on better ways of increasing productivity and reduction of the
                    Skip trace and Hardcore credit portfolio as well as analyzing the risks that can be
                    prevented in the future.</li>
                    <li>Investigation of Skip accounts.</li>
                </ul>
                He therefore has all the requisite knowledge, skills and experience to handle your debt recovery process and any other financial problem.
            </p>
            <p className='p-3 border-b border-grey-300 '>The firm has experienced steady increase of clients and potential clients and in recognition of the need for quality service, the firm's policy is to recruit experienced and properly trained staff a process which is continuous in order to enable us respond to Clients' peculiar need on a timely basis.</p>
        </div>
        <div className='border-b border-red-300 pb-2 md:grid grid-cols-3 bg-slate-100 text-black'>
            <div className='p-3'>
                <h2 className='pb-2 font-semibold text-xl'>Our Vision</h2>
                <p>To be the East Africa market
                leader in debt recovery and
                explore new segments of the
                market through the use of
                technology</p>
            </div>
            <div className='p-3'>
                <h2 className='pb-2 font-semibold text-xl'>Our Mission</h2>
                <p>Maximize results through
                processes and methods geared
                towards excellence in Quality</p>
            </div>
            <div className='p-3'>
                <h2 className='pb-2 font-semibold text-xl'>Our Values</h2>
                <p>We always act in the interests of our partners, creating wealth for
                them, and opportunities and incentives for our employees.</p>
            </div>
        </div>
        <div className='bg-slate-50 text-black p-8'>
            <h1 className='pb-2 font-semibold text-xl'>WHY CHOOSE US</h1>
            <ul className='list-disc'>
                <li>We have successfully been collecting debts since 2014 throughout East Africa.</li>
                <li>Online access 24/7 to check progress on the collection of your debts.</li>
                <li>We have a very competitive collection fee structure for our services.</li>
                <li>All payments from debtors are made directly to you. This includes repayment arrangements.</li>
                <li>No charging of a collection fee each time the debtor makes a part payment.</li>
            </ul>
        </div>
    </div>
  )
}

export default About;