import React from 'react';

const Head = () => {
  return (
    <div className='bg-fuchsia-900 grid md:grid-cols-2 text-white'>
      <div className='p-3  md:mt-12'>
        <h1 className='font-medium text-2xl mb-2 md:text-4xl md:text-center md:mb-7'>Commercial Collection <br/> Agency Services</h1>
        <p className='italic md:text-center mb-3'>We rescue Your Asset!</p>
        <p className='md:text-center'>There is no cost to you unless we collect!</p>
        <div className='pt-2 md:mt-8 md:flex justify-center'>
          <button className="md:p-2 rounded-full bg-orange-600 px-3 hover:bg-transparent hover:border-orange-600
          hover:text-white duration-300 hover:border border border-transparent"><a href="https://easycollects.co.ke/">Get started</a></button>
        </div>
      </div>
      <div className='start'>
        <img className="" src="https://www.edgeverve.com/the-edge-quarterly/wp-content/uploads/2020/06/Dept-collection-banner.png" alt="img" />
      </div>
    </div>
  )
}

export default Head;