import React from 'react';

function Company() {
  return (
    <div className="bg-[url('https://images.pexels.com/photos/1707823/pexels-photo-1707823.jpeg?auto=compress&cs=tinysrgb&w=600')] ">
        <div className='p-6 text-white text-center '>
            <h1 className='pb-2 font-bold text-xl md:m-4'>Hotspot Collectors ltd is a full service business debt collection agency</h1>
            <p className='md:mx-4'>We’re based in Nairobi, serving clients nationwide. Our agency specializes in commercial collections and large claims. We resolve business debt by getting the people who control the money to pay what they owe – simple as that! We’ve perfected our craft over the past 25 years. We don’t use auto-dialers, collection scripts and form letters. Our agency’s proven collection method yields an 85% success rate on large viable claims.</p>
        </div>
    </div>
  )
}

export default Company;