import './App.css';
import Home from './pages/Home';
import {BrowserRouter ,Route, Routes } from 'react-router-dom';
import Navigation from './pages/Navigation';
import Footer from './pages/Footer';
import Service from './components/Service';
import About from './components/About';
import Contact from './components/Contact';

function App() {
  return (
    <div>
      <BrowserRouter>
      <Navigation/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/services' element={<Service/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/contact' element={<Contact/>}/>
      </Routes>
      <Footer/>
      </BrowserRouter>
    </div>   
  );
}

export default App;
