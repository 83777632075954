import React from 'react';

const Client = () => {
  return (
    <div>
        <h1 className='text-center pt-2 font-semibold'>MEET OUR CLIENTS</h1>
        <div className='px-16 py-6 md:my-8 grid md:grid-cols-4 justify-items-centergrid justify-items-center'>
            <div className='logo'>
                <img className='' src="https://campusbiz.co.ke/careers/wp-content/uploads/sites/8/2023/02/County-Government-of-Narok-1.png" alt="county" />
                <div>
                    <p className='text-center terre'>Narok County (RBC - Subcontract)</p>
                </div>
            </div>
            <div className='logo'>
                <img className='' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiIHNYwQbpziBA6CdmHCOvIuEo-gTp01TnoHVmFxATxg&s" alt="county" />
                <div>
                    <p className='text-center'>Mombasa County (RBC - Subcontract)</p>
                </div>
            </div>
            <div className='logo'>
                <img className='' src="https://i0.wp.com/www.tenderyetu.com/wp-content/uploads/2021/11/Capital-Sacco-ltd-TENDER.png?fit=646%2C475&ssl=1" alt="sacco" />
                <div>
                    <p className='text-center'>Capital Sacco (RBC - Subcontract)</p>
                </div>
            </div>
            <div className='logo'>
                <img className='' src="https://static.vecteezy.com/system/resources/previews/015/608/298/non_2x/emc-credit-repair-accounting-logo-design-on-white-background-emc-creative-initials-growth-graph-letter-logo-concept-emc-business-finance-logo-design-vector.jpg" alt="credit" />
                <div>
                    <p className='text-center'>Emara Credit Ltd</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Client;