import React from 'react';
import Head from './Head';
import Landing from '../components/Landing';
import Client from '../components/Client';
import Company from '../components/Company';


const Home = () => {
  return (
    <div>
      <Head/>
      <Company/>
      <Landing/>
      <Client/>
    </div>
  )
}

export default Home;