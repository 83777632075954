import React from 'react';

const Landing = () => {
  return (
   <div className='bg-zinc-200 p-6'>
        <div className='md:mb-8 border-b border-gray-600 pb-2'>
            <h1 className='pb-2 font-semibold text-xl text-center text-orange-600'>COMPANY RELATIONS</h1>
            <h2 className='font-medium pb-1 text-center text-orange-600'>Our Clients</h2>
            <div className='md:grid grid-cols-2 p-3'>
                <p className='pb-1 md:m-12 md:text-xl'>Our successful past efforts into debt collection & credit management prove
                our strength in the business and that’s why we have been able to retain all
                our clients and acquiring new clients every day.
                Our Clients mean the world to us. We love building relationships that help
                them succeed.
                We know that if we do good work for good clients, it will lead to other good
                work for good clients.</p>
                <img src="https://images.pexels.com/photos/5673488/pexels-photo-5673488.jpeg?auto=compress&cs=tinysrgb&w=600" alt="relation" />
            </div>
        </div>
        <div className='bg-fuchsia-900 text-white fo p-6'>
            <h1 className='pb-2 font-medium text-xl underline underline-offset-8'>BENEFITS <span className='font-bold'>CLIENTS ENJOY</span></h1>
            <p>The following are some of advantages that you will have if you trust us with your debt collection:</p>
            <ul className='list-disc'>
                <li>A guaranteed success of debt collection & credit management</li>
                <li>No in-house fixed admin wage costs.</li>
                <li>Generate and ease cash flow, cut down your bank overdraft financing
                costs</li>
                <li>Have peace of mind that your debtors are in control</li>
                <li>Focus on your core operations and see your business grow</li>
                <li>Take advantage of our flexible working hours</li>
                <li>We optimize and boast profitability. We are an investment not a sunk cost</li>
                <li>Adequate reporting and follow-up of client complaints</li>
                <li>Assessment of your clients credit worthiness</li>
                <li>Option to use our agency name as an intermediary to press for collection</li>
                <li>Use of expert advice and expert debt collection approach for a right the
                first time approach to collecting debts</li>
                <li>Close interaction of our trained staff as part of your team</li>
                <li>A smooth and continuous service throughout. Engaging your own staff
                may mean that you are left at a standstill upon their resignation. Our
                service avoids recruitment time, fixed wage costs and training time and
                costs</li>
                <li>Continuous onsite presence and communication with client.</li>
                <li>Reporting of defaulting debtors to our credit monitoring alliance Credit
                Info.</li>
                <li>Ability to provide further adds on finance and legal services.</li>
                <li>Option to enlist and consent to triangularization offsets of client debts</li>
                <li>Sending client statements on time and keeping track and trail of customer
                correspondence, telephone calls and meetings.</li>
            </ul>
        </div>
   </div>
  )
}

export default Landing;